.cookies-container ol {
    margin: 0;
    padding: 0;
}

.cookies-container table td,
.cookies-container table th {
    padding: 0;
}

.cookies-container .c17 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666667;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
}

.cookies-container .c0 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666667;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 11pt;
}

.cookies-container .c10 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: center;
}

.cookies-container .c16 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-style: normal;
}

.cookies-container .c6 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666667;
    orphans: 2;
    widows: 2;
    text-align: justify;
}

.cookies-container .c19 {
    margin-left: 112.5pt;
    padding-top: 6pt;
    padding-bottom: 6pt;
    line-height: 1;
    text-align: center;
    margin-right: 114.5pt;
}

.cookies-container .c18 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666667;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.cookies-container .c2 {
    color: #fff;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
}

.cookies-container .c3 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
}

.cookies-container .c5 {
    background-color: #fff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
}

.cookies-container .c1 {
    font-size: 12pt;
    font-weight: 700;
}

.cookies-container .c12 {
    color: #fff;
}

.cookies-container .c7 {
    font-weight: 400;
    font-size: 11pt;
}

.cookies-container .c8 {
    margin-left: 36pt;
    text-indent: -18pt;
}

.cookies-container .c4 {
    color: inherit;
    text-decoration: inherit;
}

.cookies-container .c11 {
    color: #0563c1;
}

.cookies-container .c15 {
    color: #fff;
}

.cookies-container .c9 {
    font-weight: 700;
}

.cookies-container .c14 {
    vertical-align: super;
}

.cookies-container .c13 {
    font-size: 11pt;
}

.cookies-container .title {
    padding-top: 24pt;
    color: #fff;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    line-height: 1.0791666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.cookies-container .subtitle {
    padding-top: 18pt;
    color: #666;
    font-size: 24pt;
    padding-bottom: 4pt;
    line-height: 1.0791666667;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.cookies-container li {
    color: #fff;
    font-size: 11pt;
}

.cookies-container p {
    margin: 0;
    color: #fff;
    font-size: 11pt;
}

.cookies-container h1 {
    padding-top: 24pt;
    color: #fff;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    line-height: 1.0791666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.cookies-container h2 {
    padding-top: 2pt;
    color: #365f91;
    font-size: 13pt;
    padding-bottom: 0pt;
    line-height: 1.0791666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.cookies-container h3 {
    padding-top: 14pt;
    color: #fff;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    line-height: 1.0791666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.cookies-container h4 {
    padding-top: 12pt;
    color: #fff;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    line-height: 1.0791666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.cookies-container h5 {
    padding-top: 11pt;
    color: #fff;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    line-height: 1.0791666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.cookies-container h6 {
    padding-top: 10pt;
    color: #fff;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    line-height: 1.0791666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.cookies-container,
.cookies-container p,
.cookies-container span,
.cookies-container li {
    font-size: 16px !important;
    line-height: 1.5;
    color: #fff;
}